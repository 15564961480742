import axios from '@axios'
import config from '@core/app-config/api-config'

const crudModule = options => {
  const entity = options.entity_name

  const fetchItems = (queryParams, requestConfig = {}) =>
    axios.get(`${config.host}/${entity}`, {
      params: queryParams,
      ...requestConfig,
    })

  const createItem = body => axios.post(`${config.host}/${entity}`, body)

  const deleteItem = id => axios.delete(`${config.host}/${entity}/${id}`)

  const updateItem = (id, body) =>
    axios.put(`${config.host}/${entity}/${id}`, body)

  const updateOrder = body =>
    axios.post(`${config.host}/${entity}/reorder`, body)

  const updateItemPatch = (id, body) =>
    axios.patch(`${config.host}/${entity}/${id}`, body)

  const updateItemsPatch = body => axios.patch(`${config.host}/${entity}`, body)

  const updateItems = body => axios.put(`${config.host}/${entity}`, body)

  const fetchItem = (id, params) => {
    let queryString = ''
    if (params && params.query) {
      queryString = `?${new URLSearchParams(params.query).toString()}`
    }
    return axios.get(`${config.host}/${entity}/${id}${queryString}`)
  }

  const patchItem = (id, body) =>
    axios.patch(`${config.host}/${entity}/${id}`, body)

  const arbitraryRequest = {
    patch: (path, body) =>
      axios.patch(`${config.host}/${entity}/${path}`, body),
  }

  return {
    fetchItems,
    fetchItem,
    createItem,
    updateItem,
    updateItemPatch,
    updateItemsPatch,
    deleteItem,
    updateItems,
    patchItem,
    arbitraryRequest,
    updateOrder,
  }
}

export default crudModule
